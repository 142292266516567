import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {
  
  connect() {
    this.init_select()
  }

  disconnect(){
    //$('#work_import_filter_work_import_filters_organization_numbers').multiselect('destroy');
    //$('#work_import_filter_work_import_filters_vehicle_types').multiselect('destroy');
  }

  init_select(){
    $('#work-choices .input-daterange').datepicker({
      format: "yyyy-mm-dd",
      autoclose: true,
      language: "sv",
      clearBtn: true
  });
  
  $('#calculation-choices .input-daterange').datepicker({
      format: "yyyy-mm-dd",
      autoclose: true,
      language: "sv",
      clearBtn: true
  });
  
  
  $('#klimat_filter_filter_employer_id').multiselect({
      buttonWidth: '100%',
      buttonClass:'dropdown-button btn btn-white',			
      nonSelectedText: 'Select en rapportmottagare',
      nSelectedText: ' Valda',			
      numberDisplayed: 1,	
      enableCaseInsensitiveFiltering: true,		
      templates: {
                      button: '<button type="button" class="multiselect dropdown-toggle" data-bs-toggle="dropdown"><span class="multiselect-selected-text"></span></button>',
                      filter: '<li class="multiselect-item filter"><div class="input-group input-group-sm"><span class="input-group-text"><i class="fa fa-search"></i></span><input class="form-control multiselect-search" type="text"></div></li>',
                      filterClearBtn: '<button class="btn btn-white multiselect-clear-filter" type="button"><i class="fa fa-times"></i></button>',
                      li: '<li><a href="javascript:void(0);"><label class="view-visible"></label></a></li>'
                  }
  });	
  
  $('#klimat_filter_klimat_filter_vehicle_classes').multiselect({
      enableFiltering: true,
      buttonWidth: '100%',
      buttonClass:'dropdown-button btn btn-white',			
      nonSelectedText: 'Välj en eller flera Euroklasser/EU-steg',
      nSelectedText: ' Valda',
      numberDisplayed: 1,		
      enableCaseInsensitiveFiltering: true,				
      templates: {
                      button: '<button type="button" class="multiselect dropdown-toggle" data-bs-toggle="dropdown"><span class="multiselect-selected-text"></span></button>',
                      filter: '<li class="multiselect-item filter"><div class="input-group input-group-sm"><span class="input-group-text"><i class="fa fa-search"></i></span><input class="form-control multiselect-search" type="text"></div></li>',
                      filterClearBtn: '<button class="btn btn-white multiselect-clear-filter" type="button"><i class="fa fa-times"></i></button>',
                      li: '<li><a href="javascript:void(0);"><label class="view-visible"></label></a></li>'
                  }
  });
  
  $('#klimat_filter_klimat_filter_fuels').multiselect({
      enableFiltering: true,
      buttonWidth: '100%',
      buttonClass:'dropdown-button btn btn-white',			
      nonSelectedText: 'Välj en eller flera bränslen',
      nSelectedText: ' Valda',			
      numberDisplayed: 1,	
      enableCaseInsensitiveFiltering: true,		
      templates: {
                      button: '<button type="button" class="multiselect dropdown-toggle" data-bs-toggle="dropdown"><span class="multiselect-selected-text"></span></button>',
                      filter: '<li class="multiselect-item filter"><div class="input-group input-group-sm"><span class="input-group-text"><i class="fa fa-search"></i></span><input class="form-control multiselect-search" type="text"></div></li>',
                      filterClearBtn: '<button class="btn btn-white multiselect-clear-filter" type="button"><i class="fa fa-times"></i></button>',
                      li: '<li><a href="javascript:void(0);"><label class="view-visible"></label></a></li>'
                  }
  });
  
  $('#klimat_filter_klimat_filter_vehicles').multiselect({
      enableFiltering: true,
      buttonWidth: '100%',
      buttonClass:'dropdown-button btn btn-white',
      nonSelectedText: 'Välj en eller flera fordon/maskiner',
      numberDisplayed: 1,
      nSelectedText: ' Valda',
      enableCaseInsensitiveFiltering: true,
      templates: {
                      button: '<button type="button" class="multiselect dropdown-toggle" data-bs-toggle="dropdown"><span class="multiselect-selected-text"></span></button>',
                      filter: '<li class="multiselect-item filter"><div class="input-group input-group-sm"><span class="input-group-text"><i class="fa fa-search"></i></span><input class="form-control multiselect-search" type="text"></div></li>',
                      filterClearBtn: '<button class="btn btn-white multiselect-clear-filter" type="button"><i class="fa fa-times"></i></button>',
                      li: '<li><a href="javascript:void(0);"><label class="view-visible"></label></a></li>'
                  }
  });	
  } 
}
