import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {
  
  static targets = [ "sharedInputView", "isIncludeSharedData"]

  connect() {
    this.updateView()
  }

  disconnect(){
  }

  updateView(){
    if(this.isIncludeSharedDataTarget.checked){
      this.sharedInputViewTarget.classList.remove('d-none')      
    } else{
      this.sharedInputViewTarget.classList.add('d-none')
    }
  }
}
