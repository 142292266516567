import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto";

// Connects to data-controller="charts--report-generation-log-chart"
export default class extends Controller {
  static targets = ["chart"];
  static values = {
    reports: Array,
  };

  connect() {
    const reportData = this.reportsValue;

    const ctx = this.chartTarget.getContext("2d");
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: reportData.map(data => new Date(data.created_at).toLocaleString()),
        datasets: [{
          label: 'Report Generation Time',
          data: reportData.map(data => ({
            x: new Date(data.created_at).toLocaleString(),
            y: data.duration_in_seconds,
            reportId: data.report_id 
          })),
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          fill: false,
        }],
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                const reportId = tooltipItem.raw.reportId; // Access report_id
                const duration = tooltipItem.raw.y.toFixed(2); // Access duration_in_seconds
                return `Report ID: ${reportId}, Duration: ${duration} s`;
              }
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Duration (seconds)',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Date and Time',
            },
            reverse: true
          },
        },
      },
    });
  }
}
