import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {

  static targets =  [
    "section_source_content", "section_source_link", "section_source_hide"
  ]    
  
  static classes = [ "hidden" ]

  static values = {
    show: String, 
    hide: String,
    url: String,
  }

  connect() {
    console.log("report")
  }


  toggleSource(){
        
    if ( this.section_source_hideTarget.value == "1" ) {
      this.section_source_hideTarget.value = "0"

      this.section_source_contentTarget.classList.remove(this.hiddenClass)
      this.section_source_linkTarget.innerHTML = this.hideValue
    } else {
      this.section_source_hideTarget.value = "1"

      this.section_source_contentTarget.classList.add(this.hiddenClass)    
      this.section_source_linkTarget.innerHTML = this.showValue
    }

    this.updateView( { premium_report: {  is_hide_section_source: this.section_source_hideTarget.value } }  )
  }

  updateView(params){
    $.ajax({
      method: "PATCH",
      url: this.urlValue,
      data: params
    }).done(function( msg ) {
        
    });
  }
}
