import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {
  
  static targets = [ "template_by_vehicle", "template_by_cardtext", "template_by_vehicle_description", "template_by_cardtext_description"]

  connect() {
    this.valueChanged()
  }

  disconnect(){

  }

  valueChanged(){
    console.log("halo")

    if($("#template_by_vehicle").is(":checked")) {
      this.byVehicleSelected()
    }else if($("#template_by_cardtext").is(":checked")) {
      this.byCardtextSelected()
    }
  }

  byVehicleSelected(){
    $("#template_by_vehicle_description").show();
    $("#template_by_cardtext_description").hide();

    $(".template_by_vehicle_only").show();
    $(".template_by_cardtext_only").hide();
    
  }

  byCardtextSelected(){
    $("#template_by_vehicle_description").hide("slow");
    $("#template_by_cardtext_description").show("slow");

    $(".template_by_vehicle_only").hide();
    $(".template_by_cardtext_only").show();
  }

}
