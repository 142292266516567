import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    closeOnSubmit: Boolean
  }

  connect() {
    console.log("connected to modal controller" + this.closeOnSubmitValue)

    $("#modal").modal('show')

    if( this.closeOnSubmitValue ) {
      //document.addEventListener('turbo:submit-end', this.handleSubmit)
      document.addEventListener('turbo:submit-end', this.handleSubmit.bind(this))      
      document.addEventListener('jquery:ajax:success', this.close.bind(this))
    }

    console.log("value start: " +this.closeOnSubmitValue)
  }
  disconnect() {
    if( this.closeOnSubmitValue ) {
      //document.removeEventListener('turbo:submit-end', this.handleSubmit)    
      document.removeEventListener('turbo:submit-end', this.handleSubmit.bind(this))    
      document.removeEventListener('jquery:ajax:success', this.close.bind(this))
      console.log("dc")
    }
    console.log("value end: " +this.closeOnSubmitValue)
  }

  close() {
    console.log("close");
    
    $("#modal").modal('hide')
    $("#modal-frame").removeAttr("src")
    $("#video-modal-frame").removeAttr("src")

    $("#modal-3-frame").removeAttr("src")

    this.element.remove()
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      console.log("I pressed esc")
      this.close()
    }    
  }

  handleSubmit(e) {
    console.log("handleSubmit");
    if (e.detail.success) {
      this.close()    
    }
  }

  /*
  Failed in production compiling

  handleSubmit = (e) => {
    console.log("handleSubmit");
    if (e.detail.success) {
      this.close()
    }
  }
  */

}
