import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {

  static targets = [ "role", "email", "can_edit_basic", "can_basic", "edit_basic" ]
  static classes = [ "hidden" ]

  connect() {
    console.log("share")
    this.setupAutoComplete();
    this.role_changed()
  }

  setupAutoComplete(){
    const addresses = JSON.parse(this.emailTarget.dataset.addresses) // data object
    
    var options = {
      data: addresses,
      getValue: "value",
      list: {
          match: {
              enabled: true
          }
      }
    };

    $("#email-auto-complete").easyAutocomplete(options);
  }

  role_changed(){
    if( this.roleTarget.value == 1 ) {
      this.can_basicTarget.classList.remove(this.hiddenClass)
      this.edit_basicTarget.classList.add(this.hiddenClass)
      this.can_edit_basicTarget.classList.add(this.hiddenClass)            
    }else if( this.roleTarget.value == 2 ) {      
      this.can_basicTarget.classList.add(this.hiddenClass)      
      this.edit_basicTarget.classList.remove(this.hiddenClass)
      this.can_edit_basicTarget.classList.add(this.hiddenClass)
    }else if( this.roleTarget.value == 3 ) {      
      this.can_basicTarget.classList.add(this.hiddenClass)
      this.edit_basicTarget.classList.add(this.hiddenClass)
      this.can_edit_basicTarget.classList.remove(this.hiddenClass)      
    }    

  }
}
