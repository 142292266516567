import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {    
    const popover = new bootstrap.Popover(this.element)
  }


  disconnect(){
    bootstrap.Popover.getInstance(this.element).dispose()
  }
}