import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

//rails stimulus:manifest:update #update
export default class extends Controller {
  
  static targets = [ "shareByVehicle", "shareByProjectNumber", "shareByEmployer",
                    "viewVehicleType", "viewProjectNumber", "viewEmployer", 
                    "email", "selectCompany", "emailView", "companyView", "btnSelectCompany", "btnRemoveCompany",
                    "sharedWithCompanyId"
                  ]

  static values = {
    companyId: Number
  }

  connect() {
    this.valueChanged()

    this.setupCompany()
  }

  disconnect(){
  }

  valueChanged(){
    
    if(this.shareByVehicleTarget.checked) {
      this.byVehicleSelected()
    }else if(this.shareByProjectNumberTarget.checked) {
      this.byProjectNameSelected()
    }else if(this.shareByEmployerTarget.checked) {
      this.byEmployerSelected()
    }
  }

  byVehicleSelected(){    
    this.viewVehicleTypeTarget.classList.remove('d-none')
    this.viewProjectNumberTarget.classList.add('d-none')
    this.viewEmployerTarget.classList.add('d-none')
  }

  byProjectNameSelected(){    
    this.viewVehicleTypeTarget.classList.add('d-none')
    this.viewProjectNumberTarget.classList.remove('d-none')
    this.viewEmployerTarget.classList.add('d-none')
  }  

  byEmployerSelected(){
    this.viewVehicleTypeTarget.classList.add('d-none')
    this.viewProjectNumberTarget.classList.add('d-none')
    this.viewEmployerTarget.classList.remove('d-none')
  }
  setupCompany(){
    console.log(this.sharedWithCompanyIdTarget.value)
    if(this.sharedWithCompanyIdTarget.value) {
      this.emailViewTarget.classList.add('d-none')
      this.btnSelectCompanyTarget.classList.add('d-none')

      if(this.hasBtnRemoveCompanyTarget){
        this.btnRemoveCompanyTarget.classList.remove('d-none')
      }      
    }
  }

  async emailChanged() {
    const response = await get('/share_simple_calculations/find_company_by_email', {
      query: { q: this.emailTarget.value },
      responseKind: 'turbo-stream'
    })

    if(response.ok) {      
    } else {
      console.log(response)
    }        
  }

  companySelected(){
    let companyId = this.btnSelectCompanyTarget.getAttribute('data-company-id')    
    this.sharedWithCompanyIdTarget.value = companyId

    this.emailViewTarget.classList.add('d-none')
    this.btnSelectCompanyTarget.classList.add('d-none')
    this.btnRemoveCompanyTarget.classList.remove('d-none')
  }

  companyRemoved(){
    this.sharedWithCompanyIdTarget.value = ""
    this.emailViewTarget.classList.remove('d-none')
    this.companyViewTarget.innerHTML = ""
  }
}
