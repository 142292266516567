import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "email", "paper_invoice", "email_invoice", "edi_invoice", "iaid", "plan", "same_address", "co_address", "address", "post_number", "city", "invoice_co_address", "invoice_address", "invoice_post_number", "invoice_city"]

  initialize() {
    console.log('stimulus registration!');
  }

  connect() {

    this.planChanged(); 
    
    this.invoiceDefaultView();    
    this.iaidChanged();
  }

  disconnet() {
    console.log('stimulus disconnect! registration');
  }

  invoiceDefaultView(){
    if($("#paper-invoice").is(":checked")){
      this.updateEmailInvoiceSection(false) ;
      this.updateEdiInvoiceSection(false);
    } else if($("#email-invoice").is(":checked")){
      this.updateEmailInvoiceSection(true) ;
      this.updateEdiInvoiceSection(false);
    } else if($("#edi-invoice").is(":checked")){
      this.updateEmailInvoiceSection(false) ;
      this.updateEdiInvoiceSection(true);
    }else{
      this.updateEmailInvoiceSection(false) ;
      this.updateEdiInvoiceSection(false);
    }


  }

  emailChanged() {
    $.post( "/users/email_company_status", { user: { email: this.emailTarget.value} } );
  }

  paperInvoiceChanged(){
    this.updateEmailInvoiceSection(false) ;
    this.updateEdiInvoiceSection(false) ;
    this.updateInvoiceAddressMonkey();
  }
  emailInvoiceChanged(){
    this.updateEmailInvoiceSection(true) ;
    this.updateEdiInvoiceSection(false) ;
    this.updateInvoiceAddressMonkey();
  }
  ediInvoiceChanged(){
    this.updateEmailInvoiceSection(false) ;
    this.updateEdiInvoiceSection(true) ;
    this.updateInvoiceAddressMonkey();
  }

  updateEmailInvoiceSection(show = false){
    if(show) {
      $("#invoice-delivery-email-section").slideDown("slow");
    }else{
      $("#invoice-delivery-email-section").slideUp("slow");
    }
  }

  updateEdiInvoiceSection(show){
    if(show) {
      $("#invoice-delivery-edi-section").slideDown("slow");
    }else{
      $("#invoice-delivery-edi-section").slideUp("slow");
    }    
  }

  iaidChanged() {
    
    if (this.iaidTarget.value == "manual") {
      $("#iaid_manual").slideDown("slow");
      $("#iaid_gln").slideUp("slow");
      $("#iaid_ovt").slideUp("slow");
    } else if  (this.iaidTarget.value == "peppol") {
      $("#iaid_manual").slideUp("slow");
      $("#iaid_gln").slideUp("slow");
      $("#iaid_ovt").slideDown("slow");
    } else {
      $("#iaid_manual").slideUp("slow");
      $("#iaid_gln").slideDown("slow");
      $("#iaid_ovt").slideUp("slow");
    }
    
  }

  planChanged() {    
    $.get( "/home/plan_info", { plan_id: this.planTarget.value } );
  }

  sameAddressChanged(){
    var status = $('#same-address').is(':checked');    

    if(status) {
      $("#user_invoice_co_address").prop('readonly', true);
      $("#user_invoice_address").prop('readonly', true);
      $("#user_invoice_post_number").prop('readonly', true);
      $("#user_invoice_city").prop('readonly', true);
      
      this.addressChanged()
    }else{
      $("#user_invoice_co_address").prop('readonly', false);
      $("#user_invoice_address").prop('readonly', false);
      $("#user_invoice_post_number").prop('readonly', false);
      $("#user_invoice_city").prop('readonly', false);
    }
  }

  addressChanged() {
    var status = $('#same-address').is(':checked'); 
    if (status) {
      this.invoice_co_addressTarget.value = this.co_addressTarget.value;
      this.invoice_addressTarget.value = this.addressTarget.value;
      this.invoice_post_numberTarget.value = this.post_numberTarget.value;
      this.invoice_cityTarget.value = this.cityTarget.value;    

      $('#user_invoice_address').change();
      $('#user_invoice_post_number').change();
      $('#user_invoice_city').change();
    }
    
  }

  /*registeration page doesn't get active if input with error is removed. we have to trigger change on any field*/
  updateInvoiceAddressMonkey(){
    setTimeout(function() { $('#user_first_last_name').change(); }, 1000);
  }
}
