import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {

  static targets = [ "distributed", "remaining", "volumeInputs", "btnSubmit", "notice" ]
  static classes = [ "success", "danger" ]

  connect(){        
    this.update()
  }

  update(){
    console.log("Updaing")
    
    const total = 100
    let distributed = this.calculateVolume()
    let remaining = 0


    remaining = total - distributed

    if( remaining < 0 ){
      remaining = 0
    }

    this.distributedTarget.innerHTML = distributed.swedish_format() + " %"
    this.remainingTarget.innerHTML = remaining.swedish_format() + " %"

    this.updateCss(distributed, remaining)

    if (distributed == 100) {
      this.enableSubmit()
    }else{
      this.disableSubmit()
    }
  }

  updateCss(distributed, remaining){
    if( distributed==100 ){
      this.distributedTarget.classList.add(this.successClass)
      this.distributedTarget.classList.remove(this.dangerClass)
    }else{
      this.distributedTarget.classList.remove(this.successClass)
      this.distributedTarget.classList.add(this.dangerClass)
    }
    
    if( remaining == 0 ){
      this.remainingTarget.classList.add(this.successClass)
      this.remainingTarget.classList.remove(this.dangerClass)
    } else{      
      this.remainingTarget.classList.remove(this.successClass)
      this.remainingTarget.classList.add(this.dangerClass)      
    }
  }

  calculateVolume(){
    let total = 0
    this.volumeInputsTargets.forEach(element => {
      total += parseFloat(element.value) || 0
    })        

    return total
  }

  enableSubmit(){
    this.btnSubmitTarget.removeAttribute("disabled");
    this.noticeTarget.style.display = 'none'
  }
  
  disableSubmit(){
    this.btnSubmitTarget.setAttribute("disabled", "disabled");
    this.noticeTarget.style.display = ''
  }
}