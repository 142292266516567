import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {
  //vehicle_class_id_hidden: vehicle_class select can't be used as readonly and when disabled it doesn't update so we use this value to update vehicle_type
  static targets = [ "vehicle_type", "vehicle_class_id_hidden", "vehicle_class", "vehicle_number", "vehicle_name", "enable_advanced_calc", "vehicle_average", 
                    "vehicle_average_unit_id", "fuel_type", "vehicle_mileage", "vehicle_total_with_unit", "vehicle_total",
                    "total_consumption", "vehicle_mileage_label", "total_consumption_unit",
                    "calculated_vehicle_mileage", "is_vehicle_mileage_range", "vehicle_mileage_start", "vehicle_mileage_end", "calculated_vehicle_mileage"]

  static values = {
    vehicleTypes: Array,
    fuelTypes: Array,
    favoriteFuelTypes: Array,
    consumptions: Array,
    mileageUnitLabels: Object
  }

  preventVehicleTypeId = "1340"
  /*
    1) Vehicle Changed
      - If user vehicle: set euro class and total vehicle (to 1) and disable them. Set vehicle title to vehicle_name
      - If admin vehicle: keep euro class and total vehicle open. Set vehicle title to vehicle_name
  */
  connect() {
    console.log("mission statements edit")

    this.init_row()
  }

  init_row(){    
    this.update_vehicle_number_and_euro_class_fields()

    this.updateEnableAdvancedFields()

    this.update_vehicle_mileage_label()

    this.updateTotalMileage()

    this.update_fuel_list()

    this.updateRequired()
  }

  vehicleFocused(event){
    this.preventVehicleTypeId = this.vehicle_typeTarget.value
  }
  
  vehicleChanged(event){

    if(!this.is_add_new_vehicle_selected(event)){
      this.update_vehicle_name()
      this.update_euro_class()
      
      this.update_vehicle_number()
      this.update_vehicle_number_and_euro_class_fields()

      this.update_average_consumption()
      this.update_vehicle_average_unit()
      this.update_fuel_list()

      //incase average is updated
      this.calculateAndUpdateTotalConsumptionOrAverageConsumption()

      this.update_vehicle_mileage_label()

      this.updateRequired()
    }    
  }

  is_add_new_vehicle_selected(event){
    if(this.vehicle_typeTarget.value == "+"){

      this.vehicle_typeTarget.value = this.preventVehicleTypeId
      event.preventDefault();
      event.preventDefault ? event.preventDefault() : event.returnValue = false;
      $('#NewVehicle').modal('show');
      return true
    }
    return false    
  }

  vehicleClassChanged(){
    this.updateVehicleClassHidden()
  }

  consumptionChanged(){
    this.update_fuel_list()

    this.update_vehicle_mileage_label()

    this.updateTotalMileage()

    this.updateRequired()
  }

  vehicleNumberChanged(){
    this.updateTotalMileage()
    
    this.updateRequired()
  }

  vehicleMileageChanged(){
    this.updateTotalMileage()
    this.updateRequired()
  }

  mileagePopupChanged(){
    this.updateMileagePopup()
    this.updateTotalMileage()
    this.updateRequired()
  }

  enableAdvancedCalcChanged(){
    this.updateEnableAdvancedFields()
    this.calculateAndUpdateTotalConsumptionOrAverageConsumption()
    this.updateRequired()
  }

  totalConsumptionChanged(){
    this.calculateAndUpdateTotalConsumptionOrAverageConsumption()
    this.updateRequired()
  }

  fuelTypeChanged(){
    this.updateRequired()
  }

  updateTotalMileage(){
    var mileage_unit = this.get_mileage_unit()

    if(mileage_unit == null){
      mileage_unit = ""
    }

    var total_mileage = 0
    total_mileage = parseFloat(this.vehicle_numberTarget.value) * parseFloat(this.vehicle_mileageTarget.value)
    this.vehicle_total_with_unitTarget.value = total_mileage + " " + mileage_unit
    this.vehicle_totalTarget.value = total_mileage

    this.calculateAndUpdateTotalConsumptionOrAverageConsumption()
  }

  calculateAndUpdateTotalConsumptionOrAverageConsumption(){
    var result = 0
    var consumption_unit = this.get_consumption_unit()
    if(consumption_unit == null) consumption_unit = ""

    if(this.enable_advanced_calcTarget.value == 0) {

      result = parseFloat(this.vehicle_numberTarget.value) * parseFloat(this.vehicle_mileageTarget.value) * parseFloat(this.vehicle_averageTarget.value)
      this.total_consumptionTarget.value = result.swedish_format()

    }else{
      let total_mileage = parseFloat(this.vehicle_numberTarget.value) * parseFloat(this.vehicle_mileageTarget.value)
      let total_consumption = parseFloat(this.total_consumptionTarget.value)

      if (total_mileage != 0) {
        result = total_consumption / total_mileage
      }      
      this.vehicle_averageTarget.value = result.swedish_format()
    }

    this.total_consumption_unitTarget.innerHTML = consumption_unit
  }

  update_vehicle_name(){
    var new_vehicle_type_id = this.vehicle_typeTarget.value

    for (let index = 0; index < this.vehicleTypesValue.length; index++) {
      let vehicleType = this.vehicleTypesValue[index]

      if(vehicleType[0] == new_vehicle_type_id) {        
        this.vehicle_nameTarget.value = vehicleType[3]
      }      
    }
  }

  update_euro_class(){
    /*
      if user vehicle and current euro class isn't as vehicle euro class then set it
    */    

    var new_vehicle_type_id = this.vehicle_typeTarget.value

    for (let index = 0; index < this.vehicleTypesValue.length; index++) {
      let vehicleType = this.vehicleTypesValue[index]

      if(vehicleType[0] == new_vehicle_type_id) {
        if ( vehicleType[1] != true && vehicleType[2] != null ){
          this.vehicle_classTarget.value = vehicleType[2]
        }
      }      
    }
    this.updateVehicleClassHidden()
  }

  /*
    Select: Vehicle class when disabled is not updated so we use a hidden field to update it
  */
  updateVehicleClassHidden(){
    this.vehicle_class_id_hiddenTarget.value = this.vehicle_classTarget.value

    if(this.vehicle_classTarget.disabled){
      this.vehicle_class_id_hiddenTarget.disabled = false
    }else{
      this.vehicle_class_id_hiddenTarget.disabled = true
    }
  }
  update_vehicle_number(){       
    var new_vehicle_type_id = this.vehicle_typeTarget.value

    for (let index = 0; index < this.vehicleTypesValue.length; index++) {
      let vehicleType = this.vehicleTypesValue[index]

      if(vehicleType[0] == new_vehicle_type_id) {
        if ( vehicleType[1] == false){
          this.vehicle_numberTarget.value = 1          
        }
      }      
    }
  }

  update_vehicle_number_and_euro_class_fields(){
    var new_vehicle_type_id = this.vehicle_typeTarget.value

    for (let index = 0; index < this.vehicleTypesValue.length; index++) {
      let vehicleType = this.vehicleTypesValue[index]

      if(vehicleType[0] == new_vehicle_type_id) {
        if ( vehicleType[1] == true){
          /*
            If Vehicle is owned by admin then keep vehicle_number and vehicle_class editable
          */
          this.vehicle_classTarget.disabled = false
          this.vehicle_numberTarget.readOnly = false
        }else{
          /*
            If Vehicle is not owned by admin then set vehicle_number and vehicle_class readonly
          */
          this.vehicle_classTarget.disabled = true
          this.vehicle_numberTarget.readOnly = true
        }
      }      
    }

    this.updateVehicleClassHidden()
  }

  update_average_consumption(){
    /*
      If advanced calc is not enabled then set selected vehicles average consumption to the row
    */
    let vehicleType = this.selected_vehicle()

    if ( vehicleType != null && this.enable_advanced_calcTarget.value == 0 && vehicleType[4] != null){
      this.vehicle_averageTarget.value = vehicleType[4].swedish_format()
    }else{
      this.vehicle_averageTarget.value = null
    }
  }

  update_vehicle_average_unit(){
    /*
      If vehicle is changed then update unit as well
    */
    let vehicleType = this.selected_vehicle()
    if ( vehicleType != null && vehicleType[5] != null){
      this.vehicle_average_unit_idTarget.value = vehicleType[5]
    }
  }

  selected_vehicle(){
    var new_vehicle_type_id = this.vehicle_typeTarget.value

    for (let index = 0; index < this.vehicleTypesValue.length; index++) {
      let vehicleType = this.vehicleTypesValue[index]
      if(vehicleType[0] == new_vehicle_type_id){
        return vehicleType
      }
    }
    return null
  }
  
  selected_consumption(){
    var selected_consumption_id = this.vehicle_average_unit_idTarget.value

    for (let index = 0; index < this.consumptionsValue.length; index++) {
      let consumption = this.consumptionsValue[index]
      if(consumption[0] == selected_consumption_id){
        return consumption
      }
    }
    return null    
  }
  update_fuel_list(){
    let consumption = this.selected_consumption()
    
    if(this.vehicle_average_unit_idTarget.value != ""){
      //load fuel based on vehicle unit

      let fuel_type_id = this.fuel_typeTarget.value

      if(consumption != null){
          this.updateSelectFuelType(consumption[1], fuel_type_id)
      }
      else{
          this.updateSelectFuelType(99, fuel_type_id)
      }      
      //$('#mission_statement_'+mission.id+'_fuel_type_id').val(mission.fuel_type_id)  
      //this.fuel_typeTarget.value = null
    }
    else{
        this.fuel_typeTarget.value = null
    }
  }

  updateSelectFuelType(primary_fuel_type_id, fuel_type_id){        
    
    console.log("Here: " + primary_fuel_type_id + " - fuel_type_id: " + fuel_type_id)
    
    var is_fuel_added  = false
    try{
        var select_options = "<option value=''>Välj drivmedel</option>";
    
        select_options += "<optgroup label='Favoritbränslen'>";
    
        
        for (let index = 0; index < this.favoriteFuelTypesValue.length; index++) {
          let fuelType = this.favoriteFuelTypesValue[index]

          if(primary_fuel_type_id == fuelType.primary_fuel_type_id){
            if(fuel_type_id == fuelType.id || fuelType.is_inactive==false) {
                select_options += "<option value='"+fuelType.id+"'>"+fuelType.name+"</option>";
            }

            if(fuel_type_id == fuelType.id) {
              is_fuel_added = true
            }
          }
        }      

        select_options += "</optgroup>";
    
        select_options += "<optgroup label='Alla bränslen'>";
    
        for (let index = 0; index < this.fuelTypesValue.length; index++) {
          let fuelType = this.fuelTypesValue[index]

          if(primary_fuel_type_id == fuelType.primary_fuel_type_id){
            if(fuel_type_id == fuelType.id || fuelType.is_inactive==false) {
                select_options += "<option value='"+fuelType.id+"'>"+fuelType.name+"</option>";	
            }
            if(fuel_type_id == fuelType.id) {
              is_fuel_added = true
            }
          }
        }
    
        select_options += "</optgroup>";
        
        
        $("#"+this.fuel_typeTarget.id)
            .empty()
            .append(select_options); 
        
        if(is_fuel_added){
          this.fuel_typeTarget.value = fuel_type_id
        }

    } catch(e) {

        //Raven.captureException(e)
        console.log("Fuel error: "+ e);
    }		
  }

  updateEnableAdvancedFields(){
    if(this.enable_advanced_calcTarget.value == 0) {
      this.vehicle_averageTarget.readOnly = false
      this.total_consumptionTarget.readOnly = true
    }else{
      this.vehicle_averageTarget.readOnly = true
      this.total_consumptionTarget.readOnly = false
    }
  }

  update_vehicle_mileage_label(){
    let selected_vehicle_type = this.selected_vehicle()
    let select_mileage_unit = this.get_mileage_unit()

    var label = this.mileageUnitLabelsValue.unit_type_not_selected

    if(selected_vehicle_type != null && select_mileage_unit == "tim") {
      if(selected_vehicle_type[6] == 1) {
        label = this.mileageUnitLabelsValue.working_hour_per_vehicle     
      }else if(selected_vehicle_type[6] == 2) {
        label = this.mileageUnitLabelsValue.working_hour_per_machine
      }else{
        label = this.mileageUnitLabelsValue.working_hour
      }      
    }else if(selected_vehicle_type != null && select_mileage_unit == "km"){
      if(selected_vehicle_type[6] == 1) {
        label = this.mileageUnitLabelsValue.mileage_km_per_vehicle
      }else if(selected_vehicle_type[6] == 2) {
        label = this.mileageUnitLabelsValue.mileage_km_per_machine
      }else{
        label = this.mileageUnitLabelsValue.mileage_km
      }
    }

    this.vehicle_mileage_labelTarget.innerHTML = label
  }

  get_mileage_unit(){
    let unit = this.vehicle_average_unit_idTarget.selectedOptions[0].text
    
    return unit.split('/')[1]
  }

  get_consumption_unit(){
    let unit = this.vehicle_average_unit_idTarget.selectedOptions[0].text
    
    return unit.split('/')[0]
  }

  updateMileagePopup(){
    if(this.is_vehicle_mileage_rangeTarget.value == 0){
      this.vehicle_mileage_startTarget.readOnly = true
      this.vehicle_mileage_endTarget.readOnly = true
      this.vehicle_mileageTarget.readOnly = false
    }else{
      this.vehicle_mileage_startTarget.readOnly = false
      this.vehicle_mileage_endTarget.readOnly = false
      this.vehicle_mileageTarget.readOnly = true

      let value = parseFloat(this.vehicle_mileage_endTarget.value) - parseFloat(this.vehicle_mileage_startTarget.value)
      
      if(value >= 0 ){
        this.vehicle_mileageTarget.value = value.swedish_format()
        this.calculated_vehicle_mileageTarget.innerHTML = value.swedish_format()
      }else{
        this.vehicle_mileageTarget.value = 0
        this.calculated_vehicle_mileageTarget.innerHTML = 0
      }
    }
  }

  updateRequired(){        
    $('.required').each(function(i, obj) {
        if ( $(obj).val() != "" ){
            $(obj).removeClass('required-field');
        }
        else{
            $(obj).addClass('required-field');
        }
    });    
  }
}