import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {
  static targets = [ "roleAdmin", "roleRegular", "roleCustom", "isManageUsers", "isManageSelf", "manageDataTypeAll", "manageDataTypeView", "manageDataTypeNone", "manageVehiclesTypeAll", "manageVehiclesTypeView", "manageVehiclesTypeNone"]

  connect() {
    console.log("Roles")
    this.roleChanged()
  }

  roleChanged(){
    if(this.roleAdminTarget.checked){
      this.isManageUsersTarget.disabled = true
      this.isManageSelfTarget.disabled = true

      this.isManageUsersTarget.checked = true
      this.isManageSelfTarget.checked = true
      
      this.manageDataTypeAllTarget.checked = true
      this.manageDataTypeAllTarget.disabled = true
      this.manageDataTypeViewTarget.disabled = true
      this.manageDataTypeNoneTarget.disabled = true

      this.manageVehiclesTypeAllTarget.checked = true
      this.manageVehiclesTypeAllTarget.disabled = true
      this.manageVehiclesTypeViewTarget.disabled = true
      this.manageVehiclesTypeNoneTarget.disabled = true

    }else if(this.roleRegularTarget.checked){
      this.isManageUsersTarget.disabled = true
      this.isManageSelfTarget.disabled = true

      this.isManageUsersTarget.checked = false
      this.isManageSelfTarget.checked = true

      this.manageDataTypeNoneTarget.checked = true
      this.manageDataTypeAllTarget.disabled = true
      this.manageDataTypeViewTarget.disabled = true
      this.manageDataTypeNoneTarget.disabled = true

      this.manageVehiclesTypeNoneTarget.checked = true
      this.manageVehiclesTypeAllTarget.disabled = true
      this.manageVehiclesTypeViewTarget.disabled = true
      this.manageVehiclesTypeNoneTarget.disabled = true

    }else {
      this.isManageUsersTarget.disabled = false
      this.isManageSelfTarget.disabled = true


      this.manageDataTypeAllTarget.disabled = false
      this.manageDataTypeViewTarget.disabled = false
      this.manageDataTypeNoneTarget.disabled = false

      this.manageVehiclesTypeAllTarget.disabled = false
      this.manageVehiclesTypeViewTarget.disabled = false
      this.manageVehiclesTypeNoneTarget.disabled = false
    }

    console.log("Data: "+ this.manageDataTypeAllTarget.checked)

    if(this.manageDataTypeAllTarget.checked){
      this.manageVehiclesTypeAllTarget.checked = true
      this.manageVehiclesTypeAllTarget.disabled = true
      this.manageVehiclesTypeViewTarget.disabled = true
      this.manageVehiclesTypeNoneTarget.disabled = true
    }
    /*
    if(this.isManageVehiclesTarget.checked){
      this.isViewVehiclesTarget.disabled = true
      this.isViewVehiclesTarget.checked = true
    }

    if(this.isManageDataTarget.checked){
      this.isViewDataTarget.disabled = true
      this.isViewDataTarget.checked = true

      this.isManageVehiclesTarget.disabled = true
      this.isManageVehiclesTarget.checked = true

      this.isViewVehiclesTarget.disabled = true
      this.isViewVehiclesTarget.checked = true
    }

    if(this.isViewDataTarget.checked){
      this.isViewVehiclesTarget.disabled = true
      this.isViewVehiclesTarget.checked = true
    } else {
      this.isViewVehiclesTarget.disabled = false
    }
    */
  }  
}
