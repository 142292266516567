(function() {
  var delegate,
    __slice = [].slice;

  delegate = function(eventName, _arg) {
    var handler, parameters;
    parameters = _arg.parameters;
    handler = function() {
      var args, data, delegatedEvent, index, name, _i, _len;
      args = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
      data = {};
      for (index = _i = 0, _len = parameters.length; _i < _len; index = ++_i) {
        name = parameters[index];
        data[name] = args[index];
      }
      delegatedEvent = new CustomEvent("jquery:" + eventName, {
        bubbles: true,
        cancelable: true,
        detail: data
      });
      return data.event.target.dispatchEvent(delegatedEvent);
    };
    return $(document).on(eventName, handler);
  };

  delegate('ajax:before', {
    parameters: ['event']
  });

  delegate('ajax:beforeSend', {
    parameters: ['event', 'xhr', 'settings']
  });

  delegate('ajax:send', {
    parameters: ['event', 'xhr']
  });

  delegate('ajax:success', {
    parameters: ['event', 'data', 'status', 'xhr']
  });

  delegate('ajax:error', {
    parameters: ['event', 'xhr', 'status', 'error']
  });

  delegate('ajax:complete', {
    parameters: ['event', 'xhr', 'status']
  });

  delegate('ajax:aborted:required', {
    parameters: ['event', 'elements']
  });

  delegate('ajax:aborted:file', {
    parameters: ['event', 'elements']
  });

}).call(this);