import { Controller } from "@hotwired/stimulus";

export default class extends Controller{

    static targets = ["add_item", "template", 
        "goodsSettingDistribute", "goodsSettingNewInput", 
        "vehicleReadingSettingDistribute", "vehicleReadingSettingNewInput", "vehicleReadingSettingEmpty",
        "vehicleReadingInputs", "goodsInputs"
    ]

    connect(){        
        this.handleSetting()
    }

    add_association(event) {
        event.preventDefault()

        var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
        this.add_itemTarget.insertAdjacentHTML('beforeBegin', content)

        this.handleSetting()
    }

    remove_association(event){        
        event.preventDefault()
        let item = event.target.closest(".nested-fields")
        item.querySelector("input[name*='_destroy']").value = 1
        item.querySelector("input[name*='volume']").removeAttribute('required');
        item.querySelector("input[name*='volume']").value = 0
        item.style.display = 'none'
    }

    handleSetting(){        
        if(this.goodsSettingDistributeTarget.checked){
            this.updateGoods(false)
        }else{
            this.updateGoods(true)
        }

        if(this.vehicleReadingSettingDistributeTarget.checked || this.vehicleReadingSettingEmptyTarget.checked){
            this.updateVehicleReading(false)
        }else{
            this.updateVehicleReading(true)
        }        
    }

    updateGoods(status){
        this.goodsInputsTargets.forEach(element => {
            element.disabled = !status
          })  
    }
    
    updateVehicleReading(status){
        this.vehicleReadingInputsTargets.forEach(element => {
            element.disabled = !status
          })  
    }
}