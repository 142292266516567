Number.prototype.swedish_format = function() {
	var n = 2;
	var x = 3;
	var s = "";
	var c = ",";
	
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ''));
	
};

/**
 * Number.prototype.format(n, x, s, c)
 * 
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */

Number.prototype.swedish_round = function() {
	var n = 0;
	var x = 3;
	var s = "";
	var c = ",";
	
	var num = Math.round(this);
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = num.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ''));
};

//Auto parse Swedish Format

var origParseFloat = parseFloat;
parseFloat = function(str){
	if(typeof str === 'undefined' || !str){
	   str = "0"
	 };
	 
	val = str.toString().replace(/ /g, "").replace(",", ".");	
	return origParseFloat(val);
};

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};
/*
$.fn.changeVal = function (v) {
    return $(this).val(v).trigger("change");
}*/