import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "plan"]

  initialize() {
    console.log('stimulus registration compelte!');
  }

  connect() {
    this.planChanged();
  }

  disconnet() {
    console.log('stimulus disconnect! registration complete');
  }

  planChanged() {    
    $.get( "/home/plan_info", { plan_id: this.planTarget.value } );
  }
}