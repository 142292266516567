import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {
  
  connect() {
    this.init_select()
    console.log("show")
  }

  disconnect(){
    $('#work_import_filter_work_import_filters_organization_numbers').multiselect('destroy');
    $('#work_import_filter_work_import_filters_vehicle_types').multiselect('destroy');
  }

  init_select(){
    $('#work_import_filter_work_import_filters_organization_numbers').multiselect({
      enableFiltering: true,
      buttonWidth: '100%',
      buttonClass:'dropdown-button btn btn-white',
      nonSelectedText: 'Välj en eller flera organisationsnummer',
      numberDisplayed: 1,
      enableCaseInsensitiveFiltering: true,
      nSelectedText: ' Valda',
      templates: {
                      button: '<button type="button" class="multiselect dropdown-toggle" data-bs-toggle="dropdown"><span class="multiselect-selected-text"></span></button>',
                      filter: '<li class="multiselect-item filter"><div class="input-group input-group-sm"><span class="input-group-text"><i class="fa fa-search"></i></span><input class="form-control multiselect-search" type="text"></div></li>',
                      filterClearBtn: '<button class="btn btn-white multiselect-clear-filter" type="button"><i class="fa fa-times"></i></button>',
                      li: '<li><a href="javascript:void(0);"><label class="view-visible"></label></a></li>'
                  }
    });

    $('#work_import_filter_work_import_filters_vehicle_types').multiselect({
        enableClickableOptGroups: true,
        enableFiltering: true,
        buttonWidth: '100%',
        buttonClass:'dropdown-button btn btn-white',
        nonSelectedText: 'Välj en eller flera fordon/maskiner',
        numberDisplayed: 1,
        enableCaseInsensitiveFiltering: true,
        nSelectedText: ' Valda',
        templates: {
                        button: '<button type="button" class="multiselect dropdown-toggle" data-bs-toggle="dropdown"><span class="multiselect-selected-text"></span></button>',
                        filter: '<li class="multiselect-item filter"><div class="input-group input-group-sm"><span class="input-group-text"><i class="fa fa-search"></i></span><input class="form-control multiselect-search" type="text"></div></li>',
                        filterClearBtn: '<button class="btn btn-white multiselect-clear-filter" type="button"><i class="fa fa-times"></i></button>',
                        li: '<li><a href="javascript:void(0);"><label class="view-visible"></label></a></li>'
                    }
    });
  } 
}
