import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {

  static targets = [ "projectNumberTitle", "organizationNumber", "customerName", "calcMileageConsumption", "calcVehicleReading", "calcVolume", "vehicleReading", "volume", "vehicleList",
                      "viewHelp" ]

  connect() {
    this.setupProjectNumberAutoComplete()
    this.setupOrganizationNumberAutoComplete()    
  }

  setupProjectNumberAutoComplete(){    
    var options = {      
      url: (project_number) => {
        return this.projectNumberTitleTarget.dataset.url+"?project_number=" + project_number;
      },
      getValue: "project_number",
      list: {
          match: {
              enabled: true
          }
      }
    };
    
    $("#"+this.projectNumberTitleTarget.id).easyAutocomplete(options);
  }

  setupOrganizationNumberAutoComplete(){    
    var options = {      
      url: (organization_number) => {
        return this.organizationNumberTarget.dataset.url+"?organization_number=" + organization_number;
      },
      getValue: "organization_number",
      list: {
          match: {
              enabled: true
          },
          onSelectItemEvent: () => {            
            var selectedItemValue = $("#"+this.organizationNumberTarget.id).getSelectedItemData().customer_name;
            $("#"+this.customerNameTarget.id).val(selectedItemValue).trigger("change");
          }
      },
      template: {
        type: "description",
        fields: {
            description: "customer_name"
        }
      }
    };

    $("#"+this.organizationNumberTarget.id).easyAutocomplete(options);
  }

  volumeUpdate(){
    this.calcVehicleReadingTarget.value = this.vehicleReadingTarget.value 

    const selectedOption = this.vehicleListTarget.options[this.vehicleListTarget.selectedIndex];    
    const consumption = parseFloat(selectedOption.dataset.consumption)

    if (consumption){
      this.calcMileageConsumptionTarget.value = consumption.swedish_format()
    }
    
    this.calcUpdate()
  }

  calcUpdate(){
    this.calcVolumeTarget.textContent = this.calculateVolume().swedish_format()
  }

  calculateVolume(){
    var mileageConsumption = parseFloat(this.calcMileageConsumptionTarget.value)
    var vehicleReading = parseFloat(this.calcVehicleReadingTarget.value)

    return mileageConsumption * vehicleReading
  }

  setVolume(){
    this.volumeTarget.value = this.calculateVolume().swedish_format()
  }  

  toggleHelp(){
    this.viewHelpTarget.classList.toggle("d-none")
  }
}