import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {

  static targets =  [
    "content", "link"
  ]
  
  static classes = [ "hidden" ]

  static values = {
    show: String, 
    hide: String,
    url: String,
    status: Boolean,
    name: String
  }

  connect() {}

  toggle(){
        
    if ( this.statusValue  ) {
      this.statusValue = false

      this.contentTarget.classList.remove(this.hiddenClass)
      this.linkTarget.innerHTML = this.hideValue
    } else {
      this.statusValue = true

      this.contentTarget.classList.add(this.hiddenClass)    
      this.linkTarget.innerHTML = this.showValue
    }

    var params_premium_report = {}
    var params = {}
    params_premium_report[this.nameValue] = this.statusValue
    params["premium_report"] = params_premium_report
    
    this.updateView(params)
  }

  updateView(params){
    $.ajax({
      method: "PATCH",
      url: this.urlValue,
      data: params
    }).done(function( msg ) {
        
    });
  }
}
