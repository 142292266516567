import { Controller } from "@hotwired/stimulus"

//rails stimulus:manifest:update #update
export default class extends Controller {

  static targets = ["viewVehicle", "viewFuel", "viewOrganization", "optionReplaceDataType", "viewAction", "vehicleTypeId", "fuelTypeId"]
  static classes = [ "show", "hide" ]

  connect(){        
    this.update()
  }

  update(){
    console.log("Updaing")

    const selectedOption = this.optionReplaceDataTypeTargets.find(option => option.checked)
    if(selectedOption) {
      const val = selectedOption.value
            
      switch(val){
        case "vehicle":                    
          this.hideAllViews()
          this.viewVehicleTarget.classList.remove(this.hideClass)
          this.showAction()
          this.vehicleTypeIdTarget.disabled = false
          break

        case "fuel":
          this.hideAllViews()
          this.viewFuelTarget.classList.remove(this.hideClass)
          this.showAction()
          this.fuelTypeIdTarget.disabled = false
          break

        case "organization":
          this.hideAllViews()
          this.viewOrganizationTarget.classList.remove(this.hideClass)
          this.showAction()
          break
        default:   
          this.vehicleTypeIdTarget.disabled = false       
          this.fuelTypeIdTarget.disabled = false
      }
    }else{
      this.hideAllViews()
    }
    
  }

  handleChange(){
    console.log("handle change")
    this.update()
  }

  hideAllViews(){
    this.viewVehicleTarget.classList.add(this.hideClass)
    this.viewFuelTarget.classList.add(this.hideClass)
    this.viewOrganizationTarget.classList.add(this.hideClass)    

    this.vehicleTypeIdTarget.disabled = true
    this.fuelTypeIdTarget.disabled = true

    this.hideAction()
  }

  hideAction(){    
    this.viewActionTarget.classList.add(this.hideClass)
  }

  showAction(){    
    this.viewActionTarget.classList.remove(this.hideClass)
  }
}