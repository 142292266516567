import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "email", "paper_invoice", "email_invoice", "ehf_invoice", "plan", "same_address", "co_address", "address", "post_number", "city", "invoice_co_address", "invoice_address", "invoice_post_number", "invoice_city"]

  initialize() {
    console.log('stimulus registration!');
  }

  connect() {
    this.planChanged(); 
  }

  disconnet() {
    console.log('stimulus disconnect! registration');
  }

  emailChanged() {
    $.post( "/users/email_company_status", { user: { email: this.emailTarget.value} } );
  }

  planChanged() {    
    $.get( "/home/plan_info", { plan_id: this.planTarget.value } );
  }

  sameAddressChanged(){
    console.log("change")
    var status = $('#same-address').is(':checked');    

    if(status) {
      //$("#user_invoice_co_address").prop('readonly', true);
      $("#user_invoice_address").prop('readonly', true);
      $("#user_invoice_post_number").prop('readonly', true);
      $("#user_invoice_city").prop('readonly', true);
      
      this.addressChanged()
    }else{
      //$("#user_invoice_co_address").prop('readonly', false);
      $("#user_invoice_address").prop('readonly', false);
      $("#user_invoice_post_number").prop('readonly', false);
      $("#user_invoice_city").prop('readonly', false);
    }
  }

  addressChanged() {
    var status = $('#same-address').is(':checked'); 
    if (status) {
      //this.invoice_co_addressTarget.value = this.co_addressTarget.value;
      this.invoice_addressTarget.value = this.addressTarget.value;
      this.invoice_post_numberTarget.value = this.post_numberTarget.value;
      this.invoice_cityTarget.value = this.cityTarget.value;    

      $('#user_invoice_address').change();
      $('#user_invoice_post_number').change();
      $('#user_invoice_city').change();
    }
    
  }

  /*registeration page doesn't get active if input with error is removed. we have to trigger change on any field*/
  updateInvoiceAddressMonkey(){
    setTimeout(function() { $('#user_first_last_name').change(); }, 1000);
  }
}
